<template>
  <container class="p-1">
    <dx-grid-with-search
      title="Activity Log"
      v-stream:initialized="initializeGrid$"
      gridName="activityLog"
      :columns="columns"
      :dataSource="dataSource"
      noDataText="Please apply filters to search data."
      :row-alternation-enabled="true"
      :pageSize="12"
      :columnChooser="true"
      :toolbar="toolbar"
      @initialized="initGrid"
      :onCellPrepared="redactLogRocket"
      :onCellClick="handleCellClick"
    >
      <template v-slot:extraActions>
        <div class="d-flex">
          <button class="btn btn-outline-primary mr-1" @click="handleClearGridFilters">
            Clear Filters
          </button>
          <button
            type="button"
            v-if="permissions.AuditLogSuperUserView"
            @click="isSuperUseView = !isSuperUseView"
            class="btn btn-outline-primary mr-1"
          >
            {{ ` ${isSuperUseView ? "Activity" : "Super"} Log` }}
          </button>
          <add-button v-if="permissions.ActivityLogCreateEdit" @click="isOpen = !isOpen" />
        </div>
      </template>

      <template v-slot:pageViewed="{ data: { data } }">
        <a
          :href="data.pageOrGridViewed"
          v-if="data.pageOrGridViewed && data.pageOrGridViewed.includes('http')"
        >
          {{ data.pageOrGridViewed }}
        </a>
        <span v-else>
          {{ data.pageOrGridViewed }}
        </span>
      </template>
      <template v-slot:actions="{ data: { data } }">
        <div class="d-flex justify-content-center">
          <icon-button
            icon="eye"
            v-tooltip.left="'View Accession'"
            @click="handleViewAccession(data)"
            class="text-primary pointer p-0"
            v-show="data.accessionNumber"
          />
          <icon-button
            v-if="data.blobName"
            icon="file-pdf"
            v-tooltip.left="'View Accession PDF'"
            class="text-secondary ml-2 pointer p-0"
            @click="handleViewPdf(data)"
            v-show="data.accessionNumber"
          />
        </div>
      </template>
    </dx-grid-with-search>
    <modal :status="isOpen" @close="isOpen = !isOpen">
      <form v-stream:submit.prevent="submit$" class="container p-3">
        <h4>Create Audit Log</h4>

        <select-input
          class="col"
          v-model="targetAccession"
          valueExpr="caseId"
          :displayExpr="data => data && `${data.accessionNumber}-${data.patientName}`"
          :dataSource="accessions"
          label="Accession No."
        />

        <text-area-input class="col" label="Comments" v-model="comments" />
        <div class="d-flex justify-content-end ml-auto">
          <button class="btn btn-danger" type="button" @click.prevent="isOpen = !isOpen">
            Cancel
          </button>
          <button class="btn btn-primary mx-2" type="submit">Submit</button>
        </div>
      </form>
    </modal>
    <modal :status="isViewerOpen" @close="isViewerOpen = !isViewerOpen">
      <PDFViewer
        class="pdf-modal"
        :url="pdfUrl"
        @download="handleDownload"
        @print="handlePrint"
        @close="isViewerOpen != isViewerOpen"
      />
    </modal>
    <modal :status="isPreviewOpen" @close="closePreview" maxWidth="90vw">
      <div class="preview-modal">
        <pre v-html="commentToPreview"></pre>
      </div>
    </modal>
  </container>
</template>

<script>
import DxGridWithSearch from "../components/common/DxGridWithSearch.vue";
import {
  createLogItem,
  filterAccessionNumber,
  filterCellUTC,
  formatDatetimeCell,
  syntaxHighlight,
  formatSSN
} from "../modules/helpers";
import { startCase } from "lodash";
import AddButton from "../components/common/AddButton.vue";
import Modal from "../components/common/Modal.vue";
import { mapState, mapGetters } from "vuex";
import SelectInput from "../components/common/SelectInput.vue";
import TextAreaInput from "../components/TextAreaInput.vue";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import auditLog from "../services/AuditLog";
import { format } from "date-fns";
import PDFViewer from "@/components/common/PDFViewer.vue";
import pathReportMessages from "@/services/pathReportMessages";
import { CasesApi, AuditLogApi } from "../services/index";
import Container from "@/components/common/Container.vue";
import IconButton from "@/components/common/IconButton.vue";
import CustomStore from "devextreme/data/custom_store";
export default {
  components: {
    DxGridWithSearch,
    AddButton,
    Modal,
    SelectInput,
    TextAreaInput,
    PDFViewer,
    Container,
    IconButton
  },
  name: "Activity-Log",
  metaInfo: {
    title: "Activity Log",
    titleTemplate: "IntelliPath - %s"
  },
  data() {
    return {
      targetAccession: null,
      comments: "",
      isOpen: false,
      isViewerOpen: false,
      currentView: null,

      pdfUrl: "",
      isSuperUseView: false,
      accessions: CasesApi.searchStore,
      isPreviewOpen: false,
      commentToPreview: ""
    };
  },
  watch: {
    isOpen(nv) {
      if (!nv) {
        this.targetAccession = null;
        this.comments = "";
      }
    }
  },
  domStreams: ["submit$", "initializeGrid$", "exportToExcel$", "navigateToCase$", "viewPdf$"],
  subscriptions() {
    return {
      exportGrid$: this.initializeGrid$.pipe(
        map(({ event }) => event.msg),
        switchMap(({ component }) => {
          component.on({
            exporting(data) {
              data.fileName = `${format(new Date(), "MM/dd/yyy")}_History_Grid`;
              return data;
            }
          });
          return this.exportToExcel$.pipe(
            tap(() => {
              let selectedRows = component.getSelectedRowsData();
              if (selectedRows.length) {
                component.exportToExcel(true);
              } else {
                selectedRows = component.getVisibleRows().map(e => e.data);
                component.exportToExcel();
              }
              return;
            })
          );
        })
      ),
      manualLog$: this.submit$.pipe(
        switchMap(async () => {
          let logItem;
          if (this.targetAccession) {
            const caseDetails = await CasesApi.getCaseById(this.targetAccession);
            logItem = createLogItem(caseDetails, 17);
          } else {
            logItem = createLogItem({}, 17);
          }
          logItem.comments = this.comments;
          return auditLog.insertLogMessage(logItem);
        }),
        tap(data => {
          if (data.id) {
            this.isOpen = !this.isOpen;
            this.dataSource.reload();
          }
        }),
        catchError((e, s) => {
          return s;
        })
      )
    };
  },
  methods: {
    async handlePrint() {
      const [caseDetails] = await CasesApi.searchStore.load({
        filter: ["accessionNumber", this.currentView.accessionNumber]
      });

      pathReportMessages.addPathReportMessage({ caseIds: [caseDetails.caseId], method: 7 });
    },
    async handleViewAccession(data) {
      const cases = await CasesApi.searchStore.load({
        filter: ["accessionNumber", data.accessionNumber]
      });
      if (cases?.length) {
        return this.$router.push({
          name: "CaseView",
          params: { caseId: cases[0].caseId }
        });
      }
      window.notify("No case available.", "error");
    },
    async handleViewPdf(data) {
      this.currentView = data;
      try {
        const pdfBuffer = await auditLog.getLogPdf(data.id);

        const blob = new Blob([pdfBuffer], { type: "application/pdf" });
        this.isViewerOpen = !this.isViewerOpen;
        this.pdfUrl = URL.createObjectURL(blob);
      } catch (e) {
        window.notify("Error loading pdf", "error");
      }
    },
    async handleDownload() {
      const [caseDetails] = await CasesApi.searchStore.load({
        filter: ["accessionNumber", this.currentView.accessionNumber]
      });

      pathReportMessages.addPathReportMessage({ caseIds: [caseDetails.caseId], method: 6 });
    },
    initGrid({ component }) {
      this.grid = component;
    },
    exportToExcel() {
      this.grid.exportToExcel(false);
    },
    handleClearGridFilters() {
      this.grid.clearFilter("row");
      this.grid.clearFilter("header");
      this.grid.clearFilter("filterValue");
      this.grid.clearFilter("search");
      this.grid.clearFilter("dataSource");
    },
    handleCellClick({ data, column }) {
      if (column.caption === "Comments" && data?.comments?.length) {
        this.isPreviewOpen = true;
        this.commentToPreview = syntaxHighlight(data.comments);
      }
    },
    closePreview() {
      this.isPreviewOpen = false;
      this.commentToPreview = "";
    }
  },
  // Actions, Activity Date, Item, Accession #, Name, DOB, SSN, MRN, ACCT#, Order#, User, IP Address, Internet Browser, Page/Grid, and Comments
  computed: {
    ...mapState(["currentUser"]),
    ...mapGetters(["permissions"]),
    dataSource() {
      const dataStoreSource = this.isSuperUseView
        ? AuditLogApi.superUserLog
        : AuditLogApi.searchStore;
      return new CustomStore({
        load(options) {
          if (Array.isArray(options?.filter) && options.filter?.length) {
            return dataStoreSource.load(options);
          }
          return new Promise(res => res({ data: [], totalCount: 0 }));
        }
      });
    },
    toolbar() {
      return {
        items: [
          {
            template: "extraActions",
            visible:
              this.permissions.AuditLogSuperUserView || this.permissions.ActivityLogCreateEdit,
            locateInMenu: "auto"
          },
          {
            widget: "dxButton",
            visible: this.permissions.ActivityLogExport,
            options: {
              icon: "fa fa-file-excel",
              onClick: this.exportToExcel,
              hint: "Click to export as excel file",
              elementAttr: {
                class: "icon-color"
              },
              text: "Export to Excel"
            },
            locateInMenu: "auto",
            showText: "inMenu"
          }
        ]
      };
    },
    columns() {
      return [
        {
          type: "buttons",
          caption: "Actions",
          allowSearch: false,
          cellTemplate: "actions"
        },
        {
          dataField: "accessionNumber",
          caption: "Case No.",
          dataType: "string",
          calculateFilterExpression: filterAccessionNumber(
            "accessionNumber",
            this.$store.state.labSettings.AccessionNumberingType
          )
        },
        {
          dataField: "name",
          dataType: "string"
        },
        {
          dataField: "orderNumber",
          dataType: "string"
        },
        {
          dataField: "caseStatus",
          dataType: "string"
        },
        {
          dataField: "user",
          dataType: "string"
        },
        {
          dataField: "ipAddress",
          dataType: "string"
        },
        {
          dataField: "internetBrowser",
          dataType: "string"
        },
        {
          dataField: "pageOrGridViewed",
          cellTemplate: "pageViewed",
          dataType: "string"
        },
        {
          dataField: "comments",
          width: "350px",
          dataType: "string"
        },
        {
          dataField: "dob",
          caption: "Date Of Birth",
          dataType: "date"
        },
        {
          dataField: "ssn",
          caption: "Social Security No.",
          dataType: "string",
          dataPrivate: "redact",
          calculateDisplayValue: data => {
            if (data.ssn) {
              return formatSSN(data.ssn);
            }
            return "";
          }
        },
        {
          dataField: "mrn",
          caption: "Medical Record No.",
          dataType: "string"
        },
        {
          dataField: "accountNumber",
          caption: "Account No.",
          dataType: "string"
        },
        {
          caption: "Activity Date",
          dataField: "activityDate",
          sortIndex: 0,
          sortOrder: "desc",
          dataType: "datetime",
          calculateDisplayValue(data) {
            return formatDatetimeCell(data.activityDate);
          },
          calculateFilterExpression: filterCellUTC("activityDate")
        },
        {
          dataField: "item",
          caption: "Item",
          lookup: {
            dataSource: {
              store: [
                "CreateAccession",
                "ChangeAccession",
                "DeleteAccession",
                "ViewAccession",
                "CreateAdmin",
                "ChangeAdmin",
                "DeleteAdmin",
                "ViewAdmin",
                "Excel",
                "Download",
                "Print",
                "Fax",
                "Email",
                "FileDrop",
                "HL7",
                "Reports",
                "Other",
                "Manual"
              ].map((item, id) => ({ item: startCase(item), id })),
              key: "id"
            },
            valueExpr: "id",
            displayExpr: "item"
          },
          dataType: "number"
        }
      ];
    }
  }
};
</script>

<style lang="scss" scoped>
.pdf-modal {
  width: 50vw;
}

.preview-modal {
  max-width: 50vw;
}
</style>
