<template>
  <div class="frozen-form" v-shortkey="shortkeys" @shortkey="handleShortkey">
    <h1>Frozen</h1>
    <SelectInput
      v-focus
      label="<u>P</u>athologist"
      v-model="frozenData.frozenPathologistId"
      :dataSource="pathologistDataSource"
      ref="frozenPathologistId"
      searchExpr="displayName"
    />
    <div class="row">
      <DatePicker
        label="Frozen D<u>a</u>te"
        class="col"
        v-model="frozenData.frozenDate"
        ref="frozenDate"
      />
      <TimePicker
        label="Received Time"
        class="col"
        v-model="frozenData.frozenReceivedTime"
        ref="frozenReceivedTime"
        :focusStateEnabled="true"
      />
      <TimePicker
        label="Called Time"
        class="col"
        v-model="frozenData.frozenCalledTime"
        ref="frozenCalledTime"
        :focusStateEnabled="true"
      />
    </div>
    <SelectInput
      label="<u>D</u>iagnosis"
      v-model="frozenData.frozenDiagnosisMacroId"
      ref="frozenDiagnosisMacroId"
      :items="macroList"
      valueExpr="macroId"
      displayExpr="macroName"
      searchMode="startswith"
      @valueChanged="handleMacroChange"
    />
    <MacroEnabledEditor
      id="frozenText"
      name="FrozenText"
      label="Frozen <u>T</u>ext"
      ref="frozenText"
      v-model="frozenData.frozenText"
      @editorReady="expandEditor"
    />
    <SubmitCancelRow
      @submit="handleSubmit"
      @cancel="handleCancel"
      :isLoading="isLoading"
      :isDisabled="formDisabled"
      :submitShortKey="submitShortKey"
    />
    <Modal :status="isMacroDialogOpen" @close="toggleMacroPopup">
      <MacroPopup
        @close="toggleMacroPopup"
        :targetType="macroDialogType"
        :targetSpecimen="currentSpecimen"
        @macroSelected="macroDialogCallback"
        :dialogFromWysiwyg="macroFromWysiwyg"
        :pathologistGuid="pathologistGuid"
      />
    </Modal>
  </div>
</template>

<script>
import { altKey, dateRangeFilter, getAltKeys } from "@/modules/helpers";
import DatePicker from "./common/DatePicker.vue";
import MacroEnabledEditor from "./common/MacroEnabledEditor.vue";
import SelectInput from "./common/SelectInput.vue";
import SubmitCancelRow from "./common/SubmitCancelRow.vue";
import TimePicker from "./common/TimePicker.vue";
import { DropdownApi, MacrosApi } from "@/services";
import { handleErrors } from "@/modules/handleErrors";
import { mapState } from "vuex";
import { MacroTypeEnum } from "@/modules/enums";
import { sortBy } from "lodash";
import Modal from "./common/Modal.vue";
import { OPEN_MACRO_POPUP, RESTORE_EDITOR_POSITION, fromBusEvent } from "@/modules/eventBus";
import MacroPopup from "./MacroPopup.vue";
import { switchMap, tap, filter } from "rxjs/operators";

export default {
  components: {
    SelectInput,
    DatePicker,
    TimePicker,
    MacroEnabledEditor,
    SubmitCancelRow,
    Modal,
    MacroPopup
  },
  props: ["formDisabled"],
  data() {
    return {
      frozenData: {
        frozenPathologistId: null,
        frozenDate: null,
        frozenReceivedTime: null,
        frozenCalledTime: null,
        frozenDiagnosisMacroId: null,
        frozenText: ""
      },
      shortkeys: getAltKeys("adprt"),
      isLoading: false,
      macroList: [],
      isMacroDialogOpen: false,
      macroDialogType: 0,
      macroFromWysiwyg: false,
      pathologistGuid: null,
      submitShortKey: altKey("s")
    };
  },
  watch: {
    "frozenData.frozenPathologistId": {
      immediate: true,
      handler(nv) {
        if (!nv) {
          this.macroList = [];
          this.pathologistGuid = null;
        } else {
          this.loadPathologistMacros(nv);
        }
      }
    }
  },
  subscriptions() {
    const openMacroFromWysiwyg$ = fromBusEvent(OPEN_MACRO_POPUP).pipe(
      filter(() => !this.isFrozenOpen),
      tap(({ type }) => {
        this.macroDialogType = type;
        this.isMacroDialogOpen = true;
        this.macroFromWysiwyg = true;
      }),
      switchMap(({ callback }) => {
        return fromBusEvent(RESTORE_EDITOR_POSITION).pipe(
          tap(macros => {
            this.macroFromWysiwyg = false;
            this.macroDialogType = 0;
            this.isMacroDialogOpen = false;
            callback(macros);
            if (macros?.length && macros[0].macroTypeId === MacroTypeEnum.Results) {
              this.frozenData.frozenDiagnosisMacroId = macros[0].macroId;
            }
          })
        );
      })
    );

    return {
      openMacroFromWysiwyg$
    };
  },
  created() {
    this.loadSpecimenData();
  },
  computed: {
    ...mapState({
      currentSpecimen: state => state.accessionStore.currentSpecimen
    }),
    pathologistDataSource() {
      return DropdownApi.searchPathologists;
    }
  },
  methods: {
    expandEditor() {
      this.$refs.frozenText.expand();
    },
    async handleSubmit() {
      if (this.formDisabled) {
        return;
      }
      function formatTime(time) {
        if (time?.length > 5) {
          const {
            groups: { formattedTime }
          } = /T(?<formattedTime>\d{2}:\d{2})/.exec(time);
          return formattedTime;
        }
        return time;
      }
      try {
        this.frozenData.frozenReceivedTime = formatTime(this.frozenData.frozenReceivedTime);
        this.frozenData.frozenCalledTime = formatTime(this.frozenData.frozenCalledTime);
        this.isLoading = true;
        const route = this.currentSpecimen?.id ? "update" : "insert";
        const updatedSpecimen = await this.$store.dispatch(`accessionStore/${route}CaseSpecimen`, {
          ...this.currentSpecimen,
          ...this.frozenData
        });
        this.$store.commit("accessionStore/setCurrentSpecimen", updatedSpecimen[0]);
        this.$emit("submit", this.frozenData);
      } catch (error) {
        handleErrors(error);
      } finally {
        this.isLoading = false;
      }
    },
    handleCancel() {
      this.$emit("cancel");
    },
    handleShortkey({ srcKey }) {
      let refToFocus = "";
      switch (srcKey) {
        case "p":
          refToFocus = "frozenPathologistId";
          break;
        case "a":
          refToFocus = "frozenDate";
          break;
        case "d":
          refToFocus = "frozenDiagnosisMacroId";
          break;
        case "t":
          refToFocus = "frozenText";
          break;
        case "r":
          if (!this.pathologistGuid) {
            window.notify("Please select a pathologist.", "warning");
            this.$refs.frozenPathologistId.focus();
            return;
          }
          this.openMacroPopup({ type: MacroTypeEnum.Results });
          break;
      }
      if (refToFocus && this.$refs[refToFocus]?.focus()) {
        this.$refs[refToFocus].focus();
      }
    },
    loadSpecimenData() {
      for (const field of Object.keys(this.frozenData)) {
        if (this.currentSpecimen[field]) {
          if (/Time/.test(field)) {
            this.frozenData[field] = "1969-12-31T" + this.currentSpecimen[field];
          } else {
            this.frozenData[field] = this.currentSpecimen[field];
          }
        }
      }
    },
    async loadPathologistMacros(pathologistId) {
      const pathologistData = await DropdownApi.searchPathologists.load({
        filter: ["id", "=", pathologistId]
      });
      if (pathologistData) {
        this.pathologistGuid = pathologistData[0]?.userId;
        const pathologistMacros = await MacrosApi.getMacrosByUserAndType({
          userId: this.pathologistGuid,
          macroTypeId: MacroTypeEnum.Results,
          loadOptions: { filter: dateRangeFilter("effectiveOn", "expiresOn") }
        });
        if (pathologistMacros?.data?.length) {
          this.macroList = sortBy(pathologistMacros.data, "macroName");
          return;
        }
      }
      this.macroList = [];
    },
    handleMacroChange({ value }) {
      if (value && !this.frozenData.frozenText) {
        MacrosApi.getMacroDetails(value).then(res => {
          this.frozenData.frozenText = res?.diagnosis;
        });
      }
    },
    toggleMacroPopup() {
      this.isMacroDialogOpen = !this.isMacroDialogOpen;
    },
    openMacroPopup({ type }) {
      this.macroDialogType = type;
      this.toggleMacroPopup();
    },
    macroDialogCallback([macro]) {
      this.frozenData.frozenDiagnosisMacroId = macro.macroId;
      this.toggleMacroPopup();
    }
  }
};
</script>

<style lang="scss" scoped>
.frozen-form {
  width: 95%;
  margin: auto;
}
</style>
