<template>
  <div class="p-1 procedure-modal container">
    <div class="d-flex justify-content-between">
      <h3>Case Orders</h3>
      <div class="d-flex justify-content-end align-items-end flex-grow-1">
        <icon-button
          type="button"
          class="btn btn-outline-primary mx-2"
          icon="print"
          @click="handleBatchPrint"
        >
          Batch Print
        </icon-button>
        <add-button
          type="button"
          @click="handleAddOrders"
          @shortkey.stop="handleAddOrders"
          v-if="permissions.CassetteProcedureCreateEdit"
          v-shortkey="shortkeyAdd"
        />
        <SelectInput
          v-model="gridSpecimen"
          displayExpr="specimenOrder"
          label="Specimen"
          name="specimen"
          class="col-2"
          :items="gridSpecimens"
        />
        <NumberInput
          class="mx-1 col-1"
          v-focus
          label="Block(s)"
          name="blocks"
          :min="1"
          :max="100"
          v-show="Boolean(gridSpecimen)"
          v-model="numberOfBlocks"
        />
      </div>
    </div>

    <dx-grid-with-search
      noDataText="No orders found."
      :columns="columns"
      :pageSize="10"
      :dataSource="dataSource"
      :editing="editing"
      @initialized="initGrid"
      :toolbar="toolbar"
      @content-ready="onContentReady"
      gridName="caseOrders"
      :onFocusedCellChanged="onFocusedCellChanged"
    >
      <template v-slot:actions="{ data: { data: procedure } }">
        <div class="d-flex justify-content-center align-items-center">
          <icon-button
            icon="pen-alt"
            class="text-primary pointer mr-2 p-0"
            role="button"
            v-tooltip.left="'Edit order.'"
            @click="editCaseProcedure(procedure)"
          />
          <icon-button
            icon="trash-alt"
            class="text-danger pointer p-0"
            role="button"
            v-tooltip.left="'Remove from case.'"
            @click="removeCaseProcedure(procedure)"
          />
        </div>
      </template>
      <template v-slot:diagnosisTemplate="{ data }">
        <input
          class="edit_input"
          :value="data.value"
          :name="data.column.dataField"
          :data-row-index="data.rowIndex"
          @change="data.setValue($event.target.value)"
          @input="handleDiagnosisText($event, data)"
        />
      </template>
      <template v-slot:diagnosisSummaryTemplate="{ data }">
        <tag-input
          :value="data.value"
          :items="getItemsForDropdown(data)"
          :name="data.column.dataField"
          :data-row-index="data.rowIndex"
          @change="data.setValue($event.target.value)"
          @input="handleSelectDiagnosis($event, data)"
          :openOnMount="true"
          :dropDownOptions="diagnosisDropDownOptions"
        />
      </template>
    </dx-grid-with-search>
    <modal :status="isAdding" @close="isAdding = false">
      <edit-procedure-popup
        ref="editProcedure"
        @close="isAdding = false"
        @addOrder="handleOrderCreated"
        :caseId="caseId"
      />
    </modal>
    <modal :status="isEditing" @close="isEditing = false">
      <div>
        <div class="d-flex">
          <h4>{{ editProcedure.specimenOrder }} - {{ editProcedure.defaultBlockNum }}</h4>
        </div>
        <procedure
          class="edit-order"
          :customOrders="[editProcedure]"
          :procedureId="editProcedure.procedureId"
          @submit="handleEditOrder"
          @close="isEditing = false"
          :isCaseOrder="Boolean(caseId)"
        />
      </div>
    </modal>
    <modal :status="isMacroOpen" @close="closeMacroWindow">
      <macro-popup
        :targetType="4"
        :procedureGeneralMacroInput="procedureGeneralMacroInput"
        @macroSelected="applyGeneralMacro"
      />
    </modal>
  </div>
</template>

<script>
import { catchError, filter, switchMap } from "rxjs/operators";
import { mapGetters, mapState } from "vuex";
import { AuditLogApi, DropdownApi, ProceduresApi, SpecimensApi } from "../services";
import DxGridWithSearch from "./common/DxGridWithSearch.vue";
import AddButton from "./common/AddButton.vue";
import Modal from "./common/Modal.vue";
import EditProcedurePopup from "./EditProcedurePopup.vue";
import Procedure from "./CodeMaintenance/Procedures/Procedure.vue";
import eventBus, { REQUEST_NUMBER_OF_BLOCKS } from "../modules/eventBus";
import {
  altKey,
  createLocalTzDateFilter,
  createLogItem,
  filterCellUTC,
  formatDatetimeCell,
  getTextFromHtml,
  toLetters,
  useGeneralMacro
} from "../modules/helpers";
import IconButton from "./common/IconButton.vue";
import ArrayStore from "devextreme/data/array_store";
import NumberInput from "@/components/common/NumberInput.vue";
import SelectInput from "@/components/common/SelectInput.vue";
import TagInput from "@/components/common/TagInput.vue";
import { AuditLogItems, SpecimenNumbersEnum } from "@/modules/enums";
import handleErrors from "@/mixins/handleErrors";
import MacroPopup from "./MacroPopup.vue";
import { format } from "date-fns";
import { cloneDeep } from "lodash";

export default {
  name: "Procedure-Popup",
  components: {
    DxGridWithSearch,
    NumberInput,
    AddButton,
    Modal,
    EditProcedurePopup,
    Procedure,
    IconButton,
    SelectInput,
    MacroPopup,
    TagInput
  },
  props: ["caseId", "inQuickSignout"],
  data() {
    return {
      gridSpecimen: null,
      numberOfBlocks_old: 0,
      paging: {
        pageSize: 10
      },
      specimensSource: [],
      selectedMacro: null,
      grid: {},
      panelOrders: [],
      isAdding: false,
      isEditing: false,
      editProcedure: {},
      caseOrder: {
        specimenId: null,
        procedureId: null,
        panelId: null,
        cassetteId: null,
        items: [],
        details: ""
      },
      orderDiagnosies: [],
      availableProcedures: [],
      isMacroOpen: false,
      procedureGeneralMacroInput: {},
      shortkeyAdd: altKey("a"),
      hasContentReadyHappened: false,
      diagnosisDropDownOptions: {
        width: "17rem"
      },
      proceduresToUpdate: [],
      lastColumn: ""
    };
  },
  mixins: [handleErrors],
  created() {
    if (this.caseId) {
      this.getCaseProcedures(this.caseId);
      SpecimensApi.getSpecimen(this.caseId).then(res => {
        this.specimensSource = res || [];
      });
      this.loadCaseDetails();
    }
    DropdownApi.getOrderDiagnosis().then(res => {
      this.orderDiagnosies = res || [];
    });
    ProceduresApi.getLabProcedures(this.currentLab).then(
      res => (this.availableProcedures = res.data || [])
    );
    if (!this.generalMacros.length) {
      this.$store.dispatch("getUserGeneralMacros");
    }
  },
  beforeDestroy() {
    if (this.inQuickSignout) {
      this.$store.commit("accessionStore/clearCaseDetails");
    }
    if (this.proceduresToUpdate.length) {
      const { caseDetails } = this;
      for (const procedure of this.proceduresToUpdate) {
        this.handleEditGridOrder(procedure, caseDetails);
      }
    }
  },
  subscriptions() {
    const numberOfBlocks$ = this.$watchAsObservable("selectedSpecimen", { deep: true }).pipe(
      filter(() => Boolean(this.gridSpecimen)),
      switchMap(async ({ newValue, oldValue }) => {
        const actualNumberOfBlocks = newValue.numberOfBlocks;
        const lastNumberOfBlocks = oldValue.numberOfBlocks;
        if (newValue?.id && newValue?.id === oldValue?.id && oldValue) {
          if (parseInt(actualNumberOfBlocks) === 0) {
            return;
          }
          if (newValue.numberOfBlocks !== this.numberOfBlocks_old) {
            this.numberOfBlocks_old = lastNumberOfBlocks;
            return this.$store.dispatch("accessionStore/increaseSpecimenCassetteQty", {
              id: newValue.id,
              numberOfBlocks: newValue.numberOfBlocks
            });
          }
        } else if (newValue?.id !== oldValue?.id) {
          this.numberOfBlocks = newValue.numberOfBlocks;
          this.numberOfBlocks_old = newValue.numberOfBlocks;
        }
      }),
      catchError((e, s) => {
        this.numberOfBlocks = this.numberOfBlocks_old;
        return s;
      })
    );

    return { numberOfBlocks$ };
  },
  computed: {
    ...mapState({
      currentLab: state => state.currentLab,
      labSettings: state => state.labSettings,
      caseDetails: state => state.accessionStore.caseDetails,
      caseSpecimens: state => state.accessionStore.specimens,
      caseProcedures: state => state.accessionStore.caseOrders,
      currentSpecimen: state => state.accessionStore.currentSpecimen,
      currentUser: state => state.currentUser,
      disableAutoAddQuickLinks: state => state.applicationSettings.disableAutoAddQuickLinks,
      confirmRemoveOrder: state => state.applicationSettings.confirmRemoveOrder,
      showOrdersGridAfterAdd: state => state.applicationSettings.showOrdersGridAfterAdd,
      defaultOrdersReportPrinter: state => state.applicationSettings.defaultOrdersReportPrinter,
      generalMacros: state => state.generalMacros
    }),
    ...mapGetters("accessionStore", ["specimenNumbering", "isReported", "isCaseEditable"]),
    ...mapGetters(["permissions"]),
    editing() {
      return {
        allowUpdating: this.permissions.CassetteProcedureCreateEdit,
        mode: "cell",
        refreshMode: "repaint",
        useIcons: true
      };
    },
    gridSpecimenOrder() {
      if (this.specimens?.length) {
        const targetSpecimen = this.specimens.find(e => e.id === this.gridSpecimen);
        if (targetSpecimen?.specimenOrder) {
          return targetSpecimen.specimenOrder;
        }
      }
      return "";
    },
    dataSource() {
      return new ArrayStore({
        data: this.caseProcedures,
        key: ["cassetteId", "procedureId", "specimenId", "slideCount"],
        onUpdated: this.handleCellUpdate
      });
    },
    specimens() {
      if (this.caseId) {
        return this.specimensSource;
      }
      return this.caseSpecimens.filter(specimen => specimen.id);
    },
    matchedMacro() {
      return this.caseOrder.panelId === this.selectedMacro?.macroId;
    },
    columns() {
      return [
        {
          dataField: "specimenOrder",
          caption: "Specimen",
          sortIndex: 0,
          sortOrder: "asc",
          filterValue: this.gridSpecimenOrder
        },
        {
          dataField: "addedOn",
          caption: "Ordered On",
          dataType: "date",
          calculateDisplayValue: data => {
            if (data.addedOn) {
              const formattedDate = formatDatetimeCell(data.addedOn);
              return format(formattedDate, "M/dd/yyyy");
            }
            return "";
          },
          calculateFilterExpression: (data, filterExpr) => {
            return createLocalTzDateFilter(data, filterExpr, "addedOn");
          }
        },
        {
          dataField: "addedBy",
          caption: "Ordered By",
          dataType: "string"
        },
        {
          dataField: "cassetteId",
          caption: "Block",
          lookup: {
            dataSource: this.caseBlocks,
            displayExpr: cassette => this.displayBlockNum(cassette.blockNum),
            valueExpr: "id"
          },
          calculateSortValue(data) {
            if (this.lookup?.calculateCellValue) {
              var value = this.calculateCellValue(data);
              return this.lookup.calculateCellValue(value);
            }
            return data.cassetteId;
          },
          sortIndex: 1,
          sortOrder: "asc"
        },
        {
          dataField: "code",
          dataType: "string"
        },
        {
          dataField: "description",
          dataType: "string"
        },
        {
          dataField: "slideLabelDescription",
          dataType: "string",
          caption: "Slide Label"
        },
        {
          dataField: "diagnosisText",
          allowEditing: true,
          editCellTemplate: "diagnosisTemplate"
        },
        {
          dataField: "procedureDiagnosisIds",
          caption: "Diagnosis",
          lookup: {
            dataSource: this.orderDiagnosies,
            valueExpr: "id",
            displayExpr: "displayName"
          },
          allowEditing: true,
          editCellTemplate: "diagnosisSummaryTemplate",
          calculateDisplayValue: data => this.diagnosisDisplayExpr(data)
        },

        {
          dataField: "detail",
          caption: "Notes To Histology",
          dataType: "string"
        },
        { dataType: "number", dataField: "slideCount", caption: "Slides" },
        { dataField: "histologyCompletedBy", caption: "Histo Complete By", dataType: "string" },
        {
          dataField: "histologyCompletedOn",
          caption: "Histo Complete On",
          dataType: "datetime",
          calculateDisplayValue: data => {
            if (data.histologyCompletedOn) {
              const formattedDate = formatDatetimeCell(data.histologyCompletedOn);
              return format(formattedDate, "M/d/yyyy h:mm a");
            }
            return "";
          },
          calculateFilterExpression: filterCellUTC("histologyCompletedOn")
        },
        { dataField: "pathologistCompletedBy", caption: "Path Complete By", dataType: "string" },
        {
          dataField: "pathologistCompletedOn",
          caption: "Path Complete On",
          dataType: "datetime",
          calculateDisplayValue: data => {
            if (data.pathologistCompletedOn) {
              const formattedDate = formatDatetimeCell(data.pathologistCompletedOn);
              return format(formattedDate, "M/d/yyyy h:mm a");
            }
            return "";
          },
          calculateFilterExpression: filterCellUTC("pathologistCompletedOn")
        },
        {
          type: "buttons",
          caption: "Actions",
          cellTemplate: "actions",
          visible: this.permissions.CassetteProcedureCreateEdit
        }
      ];
    },
    toolbar() {
      return {
        items: [
          {
            widget: "dxButton",
            options: {
              icon: "fa fa-print",
              onClick: () => this.handlePrintSelected(),
              hint: "Click to print selected orders.",
              elementAttr: {
                class: "icon-color"
              },
              text: "Print"
            },
            locateInMenu: "auto",
            showText: "inMenu"
          },
          {
            widget: "dxButton",
            options: {
              icon: "fa fa-trash-alt",
              onClick: () => this.handleDeleteSelected(),
              type: "danger"
            },
            visible: this.permissions.CassetteProcedureCreateEdit
          }
        ]
      };
    },
    gridSpecimens() {
      if (this.specimens?.length) {
        return [
          { specimenOrder: "All", id: null },
          ...[...this.specimens].sort((a, b) => (a.specimenOrder > b.specimenOrder ? 1 : -1))
        ];
      }
      return [{ specimenOrder: "All", id: null }];
    },
    selectedSpecimen() {
      return this.gridSpecimens?.find(e => e.id === this.gridSpecimen);
    },
    numberOfBlocks: {
      get() {
        let target = this.selectedSpecimen;
        if (!this.selectedSpecimen && this.gridSpecimen) {
          target = this.specimens?.find(e => e.id === this.gridSpecimen);
        }
        return target?.numberOfBlocks ?? 0;
      },
      set(value) {
        const target = this.specimens?.find(e => e.id === this.gridSpecimen);
        if (target) {
          target.numberOfBlocks = value;
        }
        return value;
      }
    },
    caseBlocks() {
      return this.specimens
        .map(specimen => {
          if (specimen.cassettes?.length) {
            return specimen.cassettes.map(block => {
              return { ...block, specimenOrder: specimen.specimenOrder };
            });
          } else {
            return [];
          }
        })
        .flat();
    }
  },
  watch: {
    numberOfBlocks(nv, ov) {
      this.numberOfBlocks_old = ov;
    },
    "caseOrder.specimenId"(nv, ov) {
      if (nv !== ov) {
        this.caseOrder.cassetteId = null;
      }
    },
    currentSpecimen(nv) {
      if (nv?.id) {
        eventBus.$emit(REQUEST_NUMBER_OF_BLOCKS);
        this.gridSpecimen = nv.id;
      }
    },
    caseOrder: {
      deep: true,
      handler(nv, ov) {
        if (nv.panelId && ov.procedureId) {
          return (nv.procedureId = null);
        }
        if (nv.procedureId && ov.panelId) {
          return (nv.panelId = null);
        }
      }
    }
  },
  methods: {
    initGrid({ component }) {
      this.grid = component;
    },
    async handleDeleteSelected() {
      const selectedOrders = this.grid.getSelectedRowsData();
      if (!selectedOrders?.length) {
        window.notify("Please select orders to deleted.", "warning");
        return null;
      }
      if (this.confirmRemoveOrder) {
        const confirm = await window.confirm(
          `You're about to remove ${selectedOrders.length} order(s). <br/> Do you wish to proceed?`
        );
        if (!confirm) {
          return null;
        }
      }
      await Promise.all(
        selectedOrders.map(order =>
          ProceduresApi.removeCaseProcedure({
            cassetteId: order.cassetteId,
            procedureOrPanelId: order.procedureId,
            idType: 1
          })
        )
      );
      const logItem = createLogItem(this.caseDetails, 1);
      const removedOrdersDetails = selectedOrders.reduce((acc, { cassetteId, code }) => {
        const blockNum = this.caseBlocks.find(cassette => cassette.id === cassetteId)?.blockNum;
        return {
          ...acc,
          [blockNum || "1"]: [...(acc[blockNum] || []), code]
        };
      }, {});
      logItem.comments = Object.keys(removedOrdersDetails)
        .map(
          blockNum => `Removed ${removedOrdersDetails[blockNum].join(", ")} from block ${blockNum}.`
        )
        .join("\n");

      await this.$store.dispatch("accessionStore/getCaseOrders", this.caseDetails.caseId);
      await AuditLogApi.insertLogMessage(logItem);
      await this.$store.dispatch("accessionStore/getCaseHeader", this.caseDetails.caseId);
      await this.handleRefreshSpecimens();
      window.notify(`${selectedOrders.length} order(s) have been removed.`, "warning");
    },
    async handleRefreshSpecimens() {
      try {
        const specimens = await SpecimensApi.getSpecimen(this.caseDetails.caseId);
        const currentSpecimen = { ...(this.currentSpecimen ?? {}) };
        const newCurrentSpecimenIdx = specimens.findIndex(e => e.id === currentSpecimen.id);

        if (newCurrentSpecimenIdx > -1) {
          const { numberOfBlocks, numberOfSlides, cassettes } = specimens[newCurrentSpecimenIdx];
          currentSpecimen.numberOfBlocks = numberOfBlocks;
          currentSpecimen.numberOfSlides = numberOfSlides;
          currentSpecimen.cassettes = cassettes;
          this.$store.commit("accessionStore/setCurrentSpecimen", currentSpecimen);
        }
        this.$store.commit("accessionStore/setCaseSpecimens", specimens);
      } catch (error) {
        handleErrors(error);
      }
    },
    handleCellUpdate({ cassetteId, procedureId, specimenId, slideCount }, values) {
      const targetCassette = this.gridSpecimens
        .find(e => e.id === specimenId)
        .cassettes.find(e => e.id === cassetteId);
      const targetProc = this.caseProcedures.find(
        proc => proc.cassetteId === cassetteId && proc.procedureId === procedureId
      );
      let order = null;
      const pendingProcedureEdit = this.proceduresToUpdate.find(e => e.id === targetProc.id);
      if (pendingProcedureEdit) {
        order = cloneDeep(pendingProcedureEdit);
        this.proceduresToUpdate = this.proceduresToUpdate.filter(
          e => e.id !== pendingProcedureEdit.id
        );
      } else {
        order = this.availableProcedures.find(e => e.id === procedureId);
      }
      const editProcedure = {
        ...order,
        numberOfSlides: slideCount,
        ...targetProc,
        defaultBlockNum: targetCassette?.blockNum ?? 1,
        ...values
      };
      this.proceduresToUpdate.push(editProcedure);
    },
    displayBlock(block) {
      return block ? `${block.specimenOrder} ${block.blockLabel}` : "";
    },
    displayBlockNum(blockNum) {
      if (this.specimenNumbering === SpecimenNumbersEnum.Numbers) {
        return toLetters(blockNum);
      }
      return blockNum;
    },
    displayOrder(data) {
      return data && `${data.code} - ${data.description} `;
    },
    async handleAddSubmit() {
      this.isAdding = false;
    },
    async handleBatchPrint() {
      const { caseId } = this.$route.params;
      try {
        await ProceduresApi.batchPrintCaseProcedures(caseId);
      } catch (error) {
        this.handleErrors(error);
      }
    },
    handleAddOrders() {
      this.isAdding = true;
    },
    getCaseProcedures() {
      const caseId = this.$route.params.caseId || this.caseId;
      if (caseId) {
        return this.$store.dispatch("accessionStore/getCaseOrders", caseId);
      }
    },
    specimenDisplay(data) {
      return data && data.id ? `Specimen: ${data.specimenOrder}` : "All Specimens";
    },
    async removeCaseProcedure(order) {
      if (!this.permissions.CassetteProcedureCreateEdit) {
        window.notify("You do not have permission to perform this action", "warning");
        return;
      }
      if (this.confirmRemoveOrder) {
        const confirm = await window.confirm("Are you sure you want to remove this procedure?");
        if (!confirm) {
          return;
        }
      }
      await this.$store.dispatch("accessionStore/removeCaseOrder", order);
      await this.handleRefreshSpecimens();
    },
    async editCaseProcedure(proc) {
      if (!this.permissions.CassetteProcedureCreateEdit) {
        window.notify("You do not have permission to perform this action", "warning");
        return;
      }
      const order = await ProceduresApi.getProcedure(proc.procedureId);
      const targetCassette = this.gridSpecimens
        .find(e => e.id === proc.specimenId)
        .cassettes.find(e => e.id === proc.cassetteId);
      this.editProcedure = {
        ...order,
        numberOfSlides: proc.slideCount,
        ...proc,
        defaultBlockNum: targetCassette?.blockNum ?? 1
      };
      this.isEditing = true;
    },
    async handleEditOrder(order) {
      try {
        const item = {
          ...order,
          blockNum: order.defaultBlockNum
        };

        await ProceduresApi.updateCaseProcedure({
          note: item.details,
          items: [item]
        });
        await this.$store.dispatch("accessionStore/getCaseOrders", this.caseDetails.caseId);
        const specimens = await SpecimensApi.getSpecimen(this.caseDetails.caseId);
        this.$store.commit("accessionStore/setCaseSpecimens", specimens);
        const logItem = createLogItem(this.caseDetails, AuditLogItems.ChangeAccession);
        logItem.comments = `Updated procedure on case ${
          this.caseDetails.caseNumber
        }. \n ${JSON.stringify(item, null, 2)}`;
        AuditLogApi.insertLogMessage(logItem);
        this.$store.dispatch("accessionStore/recalculateAutoBilling", [item.specimenId]);
        this.$store.dispatch("accessionStore/getCaseHeader", this.caseDetails.caseId);
        this.isEditing = false;
      } catch (error) {
        window.notify("An error occurred updating item.", "error");
      }
    },
    handleSubmit(data) {
      const { panelId, detail, cassetteId } = data;
      const payload = {};
      if (panelId) {
        payload.items = this.panelOrders.map(item => {
          return {
            ...item,
            detail,
            cassetteId
          };
        });
        //Logic for grabbing all of the orders from the panel.
      } else {
        payload.items;
      }
    },
    handleDiagnosisText(event, data) {
      const input = event.target.value;
      if (/\.\. /.test(input)) {
        this.isMacroOpen = true;
        this.procedureGeneralMacroInput = { input: input, index: data.rowIndex };
      }
      const macroRegex = /\.(?<macroName>[a-z0-9-]+) /i;
      if (this.generalMacros && macroRegex.test(input)) {
        const inputToReplace = useGeneralMacro(input, this.generalMacros);
        data.setValue(inputToReplace);
      }
      return;
    },
    closeMacroWindow() {
      this.isMacroOpen = false;
      this.grid.cellValue(
        this.procedureGeneralMacroInput.index,
        "diagnosisText",
        this.procedureGeneralMacroInput.input.replace(".. ", "")
      );
      this.procedureGeneralMacroInput = {};
    },
    applyGeneralMacro(macro) {
      this.isMacroOpen = false;
      const textToReplaceWith = getTextFromHtml(macro.generalText.replaceAll("<br>", " ")).trim();
      this.grid.cellValue(
        this.procedureGeneralMacroInput.index,
        "diagnosisText",
        this.procedureGeneralMacroInput.input.replace(".. ", textToReplaceWith)
      );
      this.procedureGeneralMacroInput = {};
    },
    getItemsForDropdown({ data }) {
      const orderData = this.availableProcedures.find(e => e.id === data?.procedureId);
      const { orderDiagnosisIds } = orderData;
      const diagnosesForOrder = this.orderDiagnosies.filter(e => orderDiagnosisIds.includes(e.id));
      return diagnosesForOrder;
    },
    onContentReady() {
      if (
        !this.disableAutoAddQuickLinks &&
        !this.hasContentReadyHappened &&
        this.caseId &&
        !this.caseProcedures.length &&
        this.permissions.CassetteProcedureCreateEdit
      ) {
        this.isAdding = true;
      }
      this.hasContentReadyHappened = true;
    },
    diagnosisDisplayExpr(data) {
      if (data.procedureDiagnosisIds?.length) {
        let diagnosisDisplayNames = [];
        for (const diagnosisId of data.procedureDiagnosisIds) {
          const procedure = this.orderDiagnosies.find(e => e?.id === diagnosisId);
          if (procedure) {
            diagnosisDisplayNames.push(procedure?.displayName);
          }
        }
        return diagnosisDisplayNames.join(", ");
      }
      return "";
    },
    handleSelectDiagnosis(event, data) {
      data.setValue(event);
    },
    handleOrderCreated() {
      this.isAdding = false;
      if (!this.showOrdersGridAfterAdd) {
        this.$emit("close");
      }
      this.$store.commit("accessionStore/setShouldPrintOrders", true);
    },
    async loadCaseDetails() {
      const hasKeys = Object.keys(this.caseDetails);
      if (!hasKeys?.length) {
        await this.$store.dispatch("accessionStore/getCaseDetails", this.caseId);
      }
    },
    async handlePrintSelected() {
      let orders = this.grid.getSelectedRowsData();
      if (!orders.length) {
        this.grid.selectAll();
        orders = this.grid.getSelectedRowsData();
      }
      const procedureIds = orders.map(e => e.id);
      if (procedureIds.length === 0) {
        return window.notify("No procedures selected to print.", "error");
      }
      if (!this.defaultOrdersReportPrinter) {
        window.notify(
          "No printer selected. Please select an Orders Report Printer in User Settings.",
          "error"
        );
        return;
      }
      try {
        this.isLoading = true;
        if (procedureIds.length > 0) {
          await ProceduresApi.printCassetteProcedures({
            cassetteProcedureIds: procedureIds,
            printerId: this.defaultOrdersReportPrinter || 0
          });
          window.notify("Print successful");
          this.grid.selectRows([], false);
        }
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.isLoading = false;
      }
    },
    handleEditGridOrder(order, caseDetails) {
      try {
        const item = {
          ...order,
          blockNum: order.defaultBlockNum
        };

        ProceduresApi.updateCaseProcedure({
          note: item.details,
          items: [item]
        }).then(() => {
          this.$store.dispatch("accessionStore/getCaseOrders", caseDetails.caseId);
          SpecimensApi.getSpecimen(caseDetails.caseId).then(res => {
            this.$store.commit("accessionStore/setCaseSpecimens", res);
          });
          const logItem = createLogItem(caseDetails, AuditLogItems.ChangeAccession);
          logItem.comments = `Updated procedure on case ${
            caseDetails.caseNumber
          }. \n ${JSON.stringify(item, null, 2)}`;
          AuditLogApi.insertLogMessage(logItem);
          this.$store.dispatch("accessionStore/recalculateAutoBilling", [item.specimenId]);
          this.$store.dispatch("accessionStore/getCaseHeader", caseDetails.caseId);
          this.isEditing = false;
        });
      } catch (error) {
        window.notify("Error updating orders.");
      }
    },
    onFocusedCellChanged({ column }) {
      if (this.lastColumn === "Diagnosis Text") {
        this.grid.refresh();
      }
      this.lastColumn = column.caption;
    }
  }
};
</script>

<style lang="scss" scoped>
.procedure-modal {
  .grid {
    height: 250px;
  }
  .summary {
    height: 200px;
  }
  width: 90vw !important;
  max-width: 100%;
}
.value {
  margin: 5px 2px;
}
.summary {
  overflow: auto;
}
.target_title {
  color: $primary-dark;
}
.edit_input {
  border: none;
  margin: auto;
  height: 80%;
  width: 100%;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;

    margin: 0;
  }
  -moz-appearance: textfield;
}
.dropdown {
  min-height: 16px;
}
</style>
