<template>
  <div>
    <h2>Export Macros for User(s)</h2>
    <h3>New Export</h3>
    <tag-input
      v-model="assignees"
      label="Users"
      :dataSource="userDataSource"
      :searchExpr="['userName', 'displayName']"
      :displayExpr="userDisplayExpr"
      valueExpr="id"
      placeholder="Leave blank to export for all users"
    />
    <tag-input
      v-model="macroTypes"
      label="Macro Types"
      :items="macroTypeOptions"
      :validator="$v.macroTypes"
    />
    <div class="my-2 d-flex justify-content-end align-items-center">
      <button class="btn btn-primary" @click="handleSubmit" :disabled="$v.$invalid">Submit</button>
    </div>
    <h3>Existing Exports</h3>
    <select-input
      v-model="exportToDownload"
      :dataSource="exportDataSource"
      :displayExpr="exportDisplayExpr"
    />
    <div class="my-2 d-flex justify-content-end align-items-center">
      <button class="btn btn-primary" @click="handleDownload" :disabled="!exportToDownload">
        Download
      </button>
    </div>
  </div>
</template>

<script>
import SelectInput from "./common/SelectInput.vue";
import { handleErrors } from "@/modules/handleErrors";
import { required } from "vuelidate/lib/validators";
import moment from "moment";
import { mapState } from "vuex";
import TagInput from "./common/TagInput.vue";
import { DropdownApi, ExportsApi, MacrosApi, UsersApi } from "@/services";
import DataSource from "devextreme/data/data_source";
import { downloadBlob, formatPhoneNumber, utcToLocal } from "@/modules/helpers";

export default {
  components: { TagInput, SelectInput },
  data() {
    return {
      assignees: [],
      macroTypes: [],
      userDataSource: new DataSource({ store: UsersApi.labUsersStore, sort: "lastName" }),
      macroTypeOptions: [],
      exportToDownload: null
    };
  },
  created() {
    DropdownApi.getMacroTypes().then(res => {
      this.macroTypeOptions = res || [];
    });
  },
  validations() {
    return {
      macroTypes: {
        required
      }
    };
  },
  computed: {
    ...mapState({
      currentUser: state => state.currentUser
    }),
    exportDataSource() {
      return new DataSource({
        store: ExportsApi.searchExports,
        filter: ["requestedBy", "=", this.currentUser.userName] && ["exportType", "=", "Macro"],
        sort: [{ selector: "requestedOn", desc: true }]
      });
    }
  },
  methods: {
    async handleSubmit() {
      try {
        await MacrosApi.requestUserMacroExport({
          assignees: this.assignees,
          macroTypes: this.macroTypes
        });
        const { userName, phoneNumber, sendMfaCodeByEmail, sendMfaCodeBySms } = this.currentUser;
        const alertMessage = `Your export has been requested. You will receive
        ${sendMfaCodeByEmail ? "an email at " + userName : ""}
        ${sendMfaCodeByEmail && sendMfaCodeBySms ? " and " : ""}
        ${sendMfaCodeBySms ? "a text message at " + formatPhoneNumber(phoneNumber) : ""}
        when it is ready to download below.`;
        window.alert(alertMessage);
        this.$emit("close");
      } catch (error) {
        handleErrors(error);
      }
    },
    async handleDownload() {
      const exportData = await ExportsApi.searchExports.load({
        filter: ["id", "=", this.exportToDownload]
      });
      try {
        const file = await ExportsApi.downloadExport(this.exportToDownload);
        downloadBlob(
          file,
          `MACRO_EXPORT_${moment(utcToLocal(exportData.requestedOn)).format("M-D-yyyy_h-mma")}`,
          "xlsx"
        );
      } catch (error) {
        if (error?.response?.data && error.response.data.includes("does not exist")) {
          window.alert("Export not currently available. Please try again later.");
        } else {
          handleErrors(error);
        }
      }
    },
    userDisplayExpr(data) {
      return `${data.lastName}, ${data.firstName} (${data.userName})`;
    },
    exportDisplayExpr(data) {
      if (data?.requestedOn) {
        return `${moment(utcToLocal(data.requestedOn)).format(
          "M/D/yyyy h:mm a"
        )} (${data.macroTypes.join(", ")})`;
      }
      return "";
    }
  }
};
</script>

<style lang="scss" scoped></style>
