<template>
  <div>
    <h2>Specimen Protocol</h2>
    <div class="d-flex">
      <div class="col-4 specimen-label"><b>Specimen</b></div>
      <div class="col"><b>Protocol</b></div>
    </div>
    <div v-for="(specimen, idx) of specimens" v-bind:key="specimen.specimenOrder">
      <div class="d-flex">
        <label class="col-4 pt-2 specimen-label">
          <b>{{ specimen.specimenOrder }}</b></label
        >

        <SelectInput
          class="col"
          :id="'specimenOrder' + idx"
          :noLabel="true"
          :items="protocolOptions"
          v-model="specimen.protocolId"
          ref="specimens"
          @valueChanged="$evt => valueChanged(specimen.specimenIndex, $evt)"
          searchMode="startsWith"
        />
      </div>
    </div>
    <SubmitCancelRow @submit="handleSubmit" @cancel="handleCancel" :submitShortKey="null" />
  </div>
</template>

<script>
import SelectInput from "@/components/common/SelectInput.vue";
import SubmitCancelRow from "./common/SubmitCancelRow.vue";
import { mapState } from "vuex";
const indexLookup = /specimenOrder(?<index>\d+)box/;
export default {
  components: { SelectInput, SubmitCancelRow },
  props: {
    specimens: {
      default() {
        return [];
      }
    },
    protocolOptions: {
      //! We are running array functions, if the prop is not passed we can get errors. So we default to empty array.
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      specimensToSubmit: [],
      specimensMap: {},
      protocolMap: {}
    };
  },
  created() {
    if (this.specimens?.length > 0) {
      for (const specimen of this.specimens) {
        this.specimensMap[specimen.specimenIndex] = specimen;
      }
    }
    if (this.protocolOptions?.length > 0) {
      for (const protocol of this.protocolOptions) {
        this.protocolMap[protocol.id] = protocol;
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      for (let i = 0; i < this.specimens.length; i++) {
        if (!this.specimens[i]?.protocolId) {
          this.$refs.specimens[i].focus();
          break;
        }
      }
    });
  },
  methods: {
    handleSubmit() {
      this.$emit("submit", Object.values(this.specimensMap));
    },
    handleSubmitFromEnter() {
      this.$emit("submit", Object.values(this.specimensMap));
    },
    handleCancel() {
      this.$emit("cancel");
    },
    valueChanged(specimenId, data) {
      const { value } = data;
      if (value) {
        const selectedProtocol = this.protocolMap[value];
        const targetSpecimen = this.specimensMap[specimenId];
        if (
          this.labSettings.GetSiteFromProtocolDescription &&
          selectedProtocol?.protocolDescription
        ) {
          targetSpecimen.site = selectedProtocol.protocolDescription;
        }
        if (selectedProtocol?.gross) {
          targetSpecimen.gross = selectedProtocol.gross;
        }
        if (selectedProtocol?.blocks) {
          targetSpecimen.numberOfBlocks = selectedProtocol.blocks;
        }
      }

      const regex = indexLookup.exec(data.element.id);
      const index = parseInt(regex.groups.index);
      const specimenEls = this.$refs.specimens;
      if (index === this.specimens.length - 1 && data.event.key === "Enter") {
        this.handleSubmit();
      } else if (specimenEls.length > 0) {
        const nextInput = this.$refs.specimens[index + 1];
        if (nextInput.focus) {
          nextInput.focus();
        }
      }
    }
  },
  computed: {
    ...mapState({
      labSettings: state => state.labSettings
    })
  }
};
</script>

<style lang="scss" scoped>
.specimen-label {
  text-align: right;
}
</style>
