<template>
  <form
    @submit.prevent="handleSubmit"
    class="border container formula_wrapper"
    v-shortkey="saveShortkey"
    @shortkey="handleSubmit"
  >
    <div class="row">
      <text-input
        v-focus
        label="Value"
        name="value"
        id="value"
        class="col-6"
        v-model="formula.value"
        required
        :validator="$v.formula.value"
      />
    </div>

    <div class="row mt-4 align-items-end d-flex align-items-center">
      <text-area-input
        name="word"
        id="word"
        v-model="newWord"
        label="Word"
        class="col-6"
        rows="3"
      />
      <add-button @click="handleAddWord" type="button" class="mx-2" :text="'Add Word'" />
      <button
        v-if="formula.icdWordsCsv.length"
        class="btn btn-outline-danger"
        type="button"
        @click="handleClear"
      >
        Clear
      </button>
    </div>
    <div v-if="words.length" class="row p-2 justify-content-end">
      <transition-group
        type="transition"
        name="flip-list"
        class="drop-zone d-flex flex-wrap justify-content-start w-100 border p-2"
      >
        <div class="m-1" v-for="(word, index) in words" :key="word + index">
          <div class="word-match p-2">
            <span>{{ word.value }}</span>
            <icon-button
              tabindex="5"
              @click="handleRemoveWord(word)"
              class="btn text-danger mx-1"
              type="button"
              icon="trash-alt"
            />
          </div>
        </div>
      </transition-group>
    </div>
    <div v-else class="row mt-4">
      <h6 class="text-muted mx-auto">No words found on this formula.</h6>
    </div>
    <div v-if="$v.formula.icdWords.$invalid">
      <div
        class="validation-error"
        v-for="(key, index) in Object.keys($v.formula.icdWords.$params)"
        :key="index"
      >
        <span class="small text-danger mb-2 error" v-if="!$v.formula.icdWords[key]">
          {{ validatorMsgMap[key] }}
        </span>
      </div>
    </div>
    <div class="row px-5 mt-4">
      <div class="col-8">
        <div class="d-flex align-items-center mt-1">
          <input
            name="matchOptionId"
            type="radio"
            id="appendValueFormula"
            v-model="formula.matchOptionId"
            value="0"
          />
          <label for="appendValueFormula" class="my-0 ml-2"><b>Append Value</b></label>
        </div>
        <div class="d-flex align-items-center mt-1">
          <input
            name="matchOptionId"
            type="radio"
            v-model="formula.matchOptionId"
            value="1"
            id="replaceWithPatternFormula"
          />
          <label for="replaceWithPatternFormula" class="my-0 ml-2"
            ><b>Replace With Pattern: </b></label
          >
          <text-input
            name="matchOption"
            v-if="formula.matchOptionId === 1 || formula.matchOptionId === '1'"
            :noLabel="true"
            :validator="$v.formula.replaceWithPattern"
            v-model="formula.replaceWithPattern"
            class="ml-2 w-50 align-self-end"
          />
        </div>
        <div class="d-flex align-items-center mt-1">
          <input
            name="matchOptionId"
            type="radio"
            id="warningFormula"
            v-model="formula.matchOptionId"
            value="2"
          />
          <label for="warningFormula" class="my-0 ml-2"><b>Warning</b></label>
        </div>
        <div class="d-flex align-items-center mt-1">
          <input
            name="matchOptionId"
            id="stopWithPreviousFormula"
            type="radio"
            v-model="formula.matchOptionId"
            value="3"
          />
          <label for="stopWithPreviousFormula" class="my-0 ml-2"><b>Stop with Previous</b></label>
        </div>
        <div class="d-flex align-items-center mt-2">
          <input
            name="matchOptionId"
            type="radio"
            id="useDigitFormula"
            v-model="formula.matchOptionId"
            value="4"
          />
          <label for="useDigitFormula" class="my-0 ml-2 w-25"><b>Use Digit:</b></label>
          <div
            class="d-flex w-75 align-items-center"
            v-if="formula.matchOptionId === 4 || formula.matchOptionId === '4'"
          >
            <text-input
              name="useDigit"
              :noLabel="true"
              :validator="$v.formula.useDigit"
              class="w-50 mx-2 align-self-end"
              v-model="formula.useDigit"
              @keydown.69.prevent
            />
            <input
              type="checkbox"
              id="formulaDigitWarning"
              name="useDigitWarning"
              v-model="formula.useDigitWarning"
            />
            <label for="formulaDigitWarning" class="mx-2 mt-1"><b>Warning</b></label>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="d-flex align-items-center mt-1">
          <input
            name="formulaTypeId"
            id="site"
            type="radio"
            v-model="formula.formulaTypeId"
            value="0"
          />
          <label for="site" class="my-0 ml-2"><b>Site</b></label>
        </div>
        <div class="d-flex align-items-center">
          <input
            id="diagnosis"
            name="formulaTypeId"
            type="radio"
            v-model="formula.formulaTypeId"
            value="1"
          />
          <label for="diagnosis" class="my-0 ml-2"><b>Diagnosis</b></label>
        </div>
        <div v-if="$v.formula.formulaTypeId.$invalid">
          <div
            class="validation-error"
            v-for="(key, index) in Object.keys($v.formula.formulaTypeId.$params)"
            :key="index"
          >
            <span class="small text-danger mb-2 error" v-if="!$v.formula.formulaTypeId[key]">
              {{ validatorMsgMap[key] }}
            </span>
          </div>
        </div>
        <div class="d-flex align-items-center mt-4">
          <input
            required
            name="matchTypeId"
            type="radio"
            v-model="formula.matchTypeId"
            value="0"
            id="matchAny"
          />
          <label for="matchAny" class="my-0 ml-2"><b>Match Any (or)</b></label>
        </div>
        <div class="d-flex align-items-center mt-1">
          <input
            name="matchTypeId"
            id="matchAll"
            type="radio"
            aria-label="Radio button for following text input"
            v-model="formula.matchTypeId"
            value="1"
          />
          <label for="matchAll" class="my-0 ml-2"><b>Match All (And)</b></label>
        </div>
      </div>
    </div>
    <div class="row justify-content-end px-2 mt-2">
      <button @click="$emit('close')" type="button" class="btn btn-danger mr-2">Cancel</button>
      <button
        :disabled="$v.formula.$invalid"
        type="submit"
        class="btn btn-primary mr-3"
        data-testid="saveFormula"
      >
        Save
      </button>
    </div>
  </form>
</template>

<script>
import { required, maxLength, helpers, minLength, alphaNum } from "vuelidate/lib/validators";
import { altKey, validatorMsgMapBase } from "@/modules/helpers.js";
import TextInput from "@/components/common/TextInput.vue";
import TextAreaInput from "@/components/TextAreaInput.vue";
import AddButton from "@/components/common/AddButton.vue";
import { cloneDeep } from "lodash";
import IconButton from "@/components/common/IconButton.vue";

export default {
  props: {
    selectedFormula: Object
  },
  components: { TextInput, TextAreaInput, AddButton, IconButton },
  name: "FormulaForm",
  mounted() {
    if (this.selectedFormula?.value) {
      this.formula = cloneDeep(this.selectedFormula);
    }
  },
  data: () => ({
    formula: {
      value: "",
      sortOrder: null,
      icdWordsCsv: "",
      icdWords: [],
      formulaTypeId: null,
      matchTypeId: 0,
      matchOptionId: null,
      replaceWithPattern: "",
      useDigit: "",
      useDigitWarning: false
    },
    newWord: "",
    saveShortkey: altKey("s")
  }),
  validations: {
    formula: {
      value: {
        required,
        maxLegnth: maxLength(10),
        alphaNum
      },
      icdWords: {
        minWords: minLength(1),
        required
      },
      formulaTypeId: {
        required
      },
      matchOptionId: {
        required
      },
      useDigit: {
        required(val, vm) {
          if (vm.matchOption === "5" || vm.matchOption === 5) {
            return helpers.req(val);
          }
          return true;
        },
        alphaNum(val) {
          return helpers.regex(val, /[0-9x]/i);
        }
      },
      replaceWithPattern: {
        required(val, vm) {
          if (vm.matchOption === "2" || vm.matchOption === 2) {
            return helpers.req(val);
          }
          return true;
        }
      }
    }
  },
  computed: {
    words: {
      get() {
        if (this.formula.icdWords.length) {
          return this.formula.icdWords.filter(e => !e.isDeleted);
        }
        return [];
      }
    },
    validatorMsgMap() {
      return {
        ...validatorMsgMapBase,
        minWord: "Formula must include at least one word."
      };
    }
  },
  methods: {
    handleSubmit() {
      this.$v.formula.$touch();
      if (this.$v.formula.$invalid) {
        window.notify("Please verify your input and try again.", "warning");

        return;
      }
      return this.$emit("submit", this.formula);
    },
    handleClear() {
      this.formula.icdWords.forEach((e, i) => {
        if (e.id) {
          e.isDeleted = true;
        } else {
          this.formula.icdWords.splice(i, 1);
        }
      });
    },
    handleRemoveWord(word) {
      if (word.id) {
        return (word.isDeleted = true);
      } else {
        this.formula.icdWords = this.formula.icdWords.filter(e => {
          return e.value != word.value;
        });
      }
    },
    handleAddWord() {
      const currentWords = this.formula.icdWords.filter(e => !e?.isDeleted).map(e => e.value);
      this.newWord.split(",").forEach(word => {
        const value = word.trim();
        if (!currentWords.includes(value)) {
          this.formula.icdWords.push({
            value
          });
        }
      });

      return (this.newWord = "");
    }
  }
};
</script>

<style lang="scss" scoped>
.word-match {
  border: 1px solid $secondary;
  border-radius: 8px;
  background-color: $primary;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    font-weight: 600;
    color: white;
  }
  &:focus,
  &:hover {
    border: 1px solid $red;
  }
}
.over {
  border: 3px dotted #666;
}

.flip-list-move {
  transition: transform 0.5s;
}
.flip-list {
  display: flex;
}
.no-move {
  transition: transform 0s;
}
.formula_wrapper {
  width: 50vw;
}
</style>
