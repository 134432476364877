<template>
  <div class="spell-check-box-contents" v-shortkey="shortkeys" @shortkey="handleShortkey">
    <div
      class="d-flex justify-content-between align-items-start move-word"
      @mousedown.stop="handleMouseDown"
    >
      <h4>{{ wordToCheck.word }}:</h4>
      <div>
        <IconButton
          v-tooltip="'Click to edit user dictionary.'"
          class="close-btn btn p-0 mx-1"
          icon="cog"
          ref="button"
          @click.prevent="toggleDictionaryMaintenance"
        />
        <IconButton
          class="close-btn btn p-0"
          icon="times"
          ref="button"
          @click.prevent="handleClose"
        />
      </div>
    </div>
    <div v-if="!wordToCheck.suggestions.length">No suggestions found.</div>
    <div v-else>
      <div>Select the Word, then Select Your Action</div>
      <div class="row px-2">
        <div
          v-for="columnIndex of Array.from(Array(numberOfColumns).keys())"
          v-bind:key="'column' + columnIndex"
          class="col px-0"
        >
          <div v-for="(suggestion, idx) of caseAdjustedSuggestions" v-bind:key="suggestion">
            <div v-if="checkColumn(idx, columnIndex)">
              <div
                class="suggestion"
                :class="idx === selectedSuggestionIndex ? 'selected-suggestion' : ''"
                @click="handleClickSuggestion(idx)"
                @dblclick="handleDblClickSuggestion(idx)"
              >
                {{ suggestion }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between">
      <button
        class="btn btn-primary m-1"
        v-if="wordToCheck.suggestions.length"
        @click="handleReplaceWord"
      >
        Replac<u>e</u>
      </button>
      <button class="btn btn-primary m-1" @click="handleIgnoreWord"><u>I</u>gnore</button>
      <button class="btn btn-primary m-1" @click="handleAddWord"><u>A</u>dd</button>
    </div>
    <div class="d-flex justify-content-between">
      <button class="btn btn-primary m-1" @click="handleIgnoreAll">I<u>g</u>nore All</button>
      <button
        class="btn btn-primary m-1"
        v-if="wordToCheck.suggestions.length"
        @click="handleReplaceAll"
      >
        <u>R</u>eplace All
      </button>
    </div>
    <div>Note: No ALT required for hot-keys</div>
    <Modal :status="isDictionaryMaintenanceOpen" @close="toggleDictionaryMaintenance">
      <EditUserDictionary :userId="currentUser.id" @close="toggleDictionaryMaintenance" />
    </Modal>
  </div>
</template>

<script>
import { SpellCheckApi } from "@/services";
import { mapState } from "vuex";
import IconButton from "./common/IconButton.vue";
import Modal from "./common/Modal.vue";
import EditUserDictionary from "./EditUserDictionary.vue";

export default {
  props: ["wordToCheck", "isForcedUpperCase"],
  components: { IconButton, Modal, EditUserDictionary },
  data() {
    return {
      selectedSuggestionIndex: 0,
      isDictionaryMaintenanceOpen: false
    };
  },
  computed: {
    ...mapState({
      currentUser: state => state.currentUser
    }),
    numberOfColumns() {
      if (this.wordToCheck?.suggestions?.length) {
        return Math.ceil(this.wordToCheck.suggestions.length / 5);
      }
      return 0;
    },
    selectedWordCase() {
      const { word } = this.wordToCheck;
      if (/[A-Z]/.test(word) && !/[a-z]/.test(word)) {
        return "upper";
      }
      if (/^[A-Z][^A-Z]+$/.test(word)) {
        return "start";
      }
      if (/^[^a-z]+[a-z]$/.test(word)) {
        return "end";
      }
      return "lower";
    },
    caseAdjustedSuggestions() {
      let suggestionList = this.wordToCheck.suggestions;
      return suggestionList.map(e => this.adjustWordCase(e));
    },
    shortkeys() {
      if (this.isDictionaryMaintenanceOpen) {
        return {};
      }
      return {
        enter: ["enter"],
        esc: ["esc"],
        up: ["arrowup"],
        down: ["arrowdown"],
        right: ["arrowright"],
        left: ["arrowleft"],
        a: ["a"],
        i: ["i"],
        e: ["e"],
        g: ["g"],
        r: ["r"]
      };
    }
  },
  mounted() {
    window.addEventListener("mouseup", this.handleMouseUp, true);
  },
  methods: {
    handleClickSuggestion(idx) {
      this.selectedSuggestionIndex = idx;
    },
    handleDblClickSuggestion(idx) {
      this.selectedSuggestionIndex = idx;
      this.handleReplaceWord();
    },
    handleIgnoreWord() {
      this.$emit("ignore", this.wordToCheck.word);
    },
    handleIgnoreAll() {
      this.$emit("ignoreAll", this.wordToCheck.word);
    },
    handleAddWord() {
      SpellCheckApi.addWordsToDictionary([this.wordToCheck.word.toLowerCase()]).then(res => {
        if (res) {
          window.notify("Word added to dictionary.");
          this.$emit("addWord", this.wordToCheck.word);
        } else {
          window.notify("Error adding word to dictionary.", "error");
        }
      });
    },
    handleClose() {
      this.$emit("close");
    },
    handleShortkey({ srcKey }) {
      switch (srcKey) {
        case "down":
          if (this.selectedSuggestionIndex < this.wordToCheck.suggestions.length - 1) {
            this.selectedSuggestionIndex++;
          } else {
            this.selectedSuggestionIndex = 0;
          }
          break;
        case "up":
          if (this.selectedSuggestionIndex > 0) {
            this.selectedSuggestionIndex--;
          } else {
            this.selectedSuggestionIndex = this.wordToCheck.suggestions.length - 1;
          }
          break;
        case "right":
          if (this.selectedSuggestionIndex + 5 > this.wordToCheck.suggestions.length - 1) {
            this.selectedSuggestionIndex =
              this.selectedSuggestionIndex - (this.wordToCheck.suggestions.length % 5) - 1;
          } else {
            this.selectedSuggestionIndex += 5;
          }
          break;
        case "left":
          if (this.selectedSuggestionIndex < 5) {
            if (this.selectedSuggestionIndex >= this.wordToCheck.suggestions.length % 5) {
              this.selectedSuggestionIndex =
                this.wordToCheck.suggestions.length -
                (this.wordToCheck.suggestions.length % 5) -
                5 +
                this.selectedSuggestionIndex;
            } else {
              this.selectedSuggestionIndex =
                this.wordToCheck.suggestions.length -
                (this.wordToCheck.suggestions.length % 5) +
                this.selectedSuggestionIndex;
            }
          } else {
            this.selectedSuggestionIndex -= 5;
          }
          break;
        case "a":
          this.handleAddWord();
          break;
        case "i":
          this.handleIgnoreWord();
          break;
        case "g":
          this.handleIgnoreAll();
          break;
        case "r":
          this.handleReplaceAll();
          break;
        case "enter":
        case "e":
          this.handleReplaceWord();
          break;
        case "esc":
          this.handleClose();
          break;
      }
    },
    checkColumn(itemIndex, columnIndex) {
      const columnNumber = columnIndex + 1;
      const max = columnNumber * 5;
      const min = max - 5;

      return itemIndex > min - 1 && itemIndex < max;
    },
    handleReplaceWord() {
      const word = this.wordToCheck.suggestions[this.selectedSuggestionIndex];
      this.$emit("replace", this.adjustWordCase(word));
    },
    toggleDictionaryMaintenance() {
      this.isDictionaryMaintenanceOpen = !this.isDictionaryMaintenanceOpen;
    },
    adjustWordCase(word) {
      if (this.isForcedUpperCase) {
        return word.toUpperCase();
      }
      const lastCharIndex = word.length - 1;
      switch (this.selectedWordCase) {
        case "upper":
          return word.toUpperCase();
        case "start":
          return word[0].toUpperCase() + word.slice(1);
        case "end":
          return word.slice(0, lastCharIndex).toUpperCase() + word[lastCharIndex].toLowerCase();
        default:
          return word;
      }
    },
    handleReplaceAll() {
      const word = this.wordToCheck.suggestions[this.selectedSuggestionIndex];
      this.$emit("replaceAll", {
        original: this.wordToCheck.word,
        replacement: word
      });
    },
    handleMouseDown() {
      window.addEventListener("mousemove", this.handleMouseMove, true);
    },
    handleMouseUp() {
      window.removeEventListener("mousemove", this.handleMouseMove, true);
    },
    handleMouseMove({ movementX, movementY }) {
      event.preventDefault();
      var deltaX = movementX;
      var deltaY = movementY;
      this.$emit("move", { deltaX, deltaY });
    }
  }
};
</script>

<style lang="scss" scoped>
.spell-check-box-contents {
  background-color: white;
  padding: 0.5em;
  z-index: 100000000;
  border: 1px black solid;
}
.suggestion {
  border: 1px black solid;
  border-radius: 0.5em;
  padding: 0.25em;
  margin: 0.25em;
  cursor: pointer;
  width: auto;
  font-size: 14pt;
}
.selected-suggestion {
  background-color: yellow;
}
.move-word {
  cursor: move;
}
</style>
